import React from "react";
// import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout/layout";
// import Hero from "../components/rsvpHero";
import moment from "moment";
import Fade from "react-reveal/Fade";
import TextField from "@mui/material/TextField";
import Hero from "../components/pageContent/contact/hero";
import ContactBody from "../components/pageContent/contact/body";
import StyledWrapper from "../styled-components/shared/StyledWrapper";

const FaqPage = () => {
  const query = useStaticQuery(graphql`
    query FaqQuery {
      contentfulLongText(title: { in: "FAQs" }) {
        longText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `);

  console.log(query, "qur");

  const longText = query.contentfulLongText.longText.childMarkdownRemark.html;

  console.log(longText);

  return (
    <Layout>
      <Helmet title='My ADU Story - FAQs' />
      <StyledWrapper style={{ marginTop: "6rem" }} small>
        {longText && <div className='FAQs-container' dangerouslySetInnerHTML={{ __html: longText }} />}
        {/* {documentToReactComponents(document)} */}
      </StyledWrapper>
      {/* <ContactBody /> */}
      {/* <Fade delay={1100}></Fade> */}
    </Layout>
  );
};

export default FaqPage;
